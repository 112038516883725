@use "sass:map";

.button{
  font-weight: $hel-font-weight-medium;

  &.clear{
    text-decoration: underline;
    text-underline-offset: 4px;
  }

  @each $key, $value in $button-padding-sizes{
    &.#{$key}{
      padding: $value;
    }
  }

  &.button--icon{
    position: relative;
    padding-right: 3.9rem;
    padding-left: 1.425rem;

    &.small{
      padding-right: 3.2rem;
    }

    .icon{
      position: absolute;
      top: 50%;
      right: 1.4rem;
      width: 1.4rem;
      height: 1.4rem;
      transform: translateY(-50%);
    }
  }
}

a.button{
  text-decoration: none;
}

.button--nomargin{
  margin: 0;
}

.buttons-row{
  display: flex;
  gap: 1rem;
}
